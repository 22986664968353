import React from 'react';
import { NumberCell } from './NumberCell';
import { formatNumberWithCommas, roundDecimal } from '@clinintell/utils/formatting';

type IsStatSignificantCellProps = {
  value: number;
  errorMargin: number;
  isPercentageBased: boolean;
  captureOverdocumentation: boolean;
};

const isStatisticallySignificant = (value: number, errorMargin: number): boolean => {
  return Math.abs(value) > errorMargin;
};

export const IsStatSignificantCell: React.FC<IsStatSignificantCellProps> = ({
  value,
  errorMargin,
  isPercentageBased,
  captureOverdocumentation
}) => {
  const isStatSig = isStatisticallySignificant(value, errorMargin);

  let bubbleClass = '';
  if (isStatSig) {
    if (captureOverdocumentation) {
      bubbleClass = value > 0 ? 'bubble-red' : 'bubble-yellow';
    } else {
      bubbleClass = value > 0 ? 'bubble-green' : 'bubble-red';
    }
  }

  return (
    <div className={`ag-grid-custom bubble ${bubbleClass}`}>
      {isPercentageBased ? (
        <div>{`${formatNumberWithCommas(roundDecimal(value, 0))}%`}</div>
      ) : (
        <NumberCell value={value} />
      )}
    </div>
  );
};
