// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import UserReducer from '@clinintell/modules/user';
import userManagementReducer from '@clinintell/modules/userManagement';
import AuthenticationStatusReducer from '@clinintell/modules/authenticationStatus';
import AppConfigReducer from '@clinintell/modules/appConfig';
import MetricsNavigationReducer from '@clinintell/modules/metricsNavigation';
import HospitalsReducer from '@clinintell/modules/hospitals';
import PhysicianGroupReducer from '@clinintell/modules/physicianGroups';
import ProviderReportsReducer from '@clinintell/modules/providerReports';
import ConditionsReducer from '@clinintell/modules/conditions';
import Auth0ConfigReducer from '@clinintell/modules/auth0Config';
import defaultDatesReducer from '@clinintell/modules/defaultDates';
import dashboardReducer from '@clinintell/modules/dashboard';
import dashboard2Reducer from '@clinintell/modules/dashboard2';
import TrainingReducer from '@clinintell/modules/training';
import TrainingAssignmentReducer from '@clinintell/modules/trainingAssignment';
import TableReducer from '@clinintell/modules/table';
import DocScoreGoalReducer from '@clinintell/modules/autoGenStrategy';
import CMIAnalysisReducer from '@clinintell/modules/cmiAnalysis';
import OrgTreeReducer from '@clinintell/modules/orgTree';
import CMIComparisonReducer from '@clinintell/modules/cmiComparison';
import MedicareComparisonReducer from '@clinintell/modules/medicareComparison';
import BreadcrumbNavigationReducer from '@clinintell/modules/breadcrumbNavigation';
import MenuNavigationReducer from '@clinintell/modules/menuNavigation';
import CMIAnalysisSelectionsReducer from '@clinintell/modules/cmiAnalysisSelections';
import SystemManagementReducer from '@clinintell/modules/systemManagement';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './modules/store';
import * as Sentry from '@sentry/react';

import 'ag-grid-community/styles/ag-grid.min.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.min.css'; // Optional theme CSS
import './components/table_v2/css/ag-grid-custom.css';
import { LicenseManager } from 'ag-grid-enterprise';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// combine reducers here
// DO NOT FORGET TO UPDATE src/tests/helpers.tsx
const rootReducer = combineReducers({
  user: UserReducer,
  userManagement: userManagementReducer,
  authenticationStatus: AuthenticationStatusReducer,
  appConfig: AppConfigReducer,
  metricsNavigation: MetricsNavigationReducer,
  hospitals: HospitalsReducer,
  physicianGroups: PhysicianGroupReducer,
  providerReports: ProviderReportsReducer,
  auth0Config: Auth0ConfigReducer,
  defaultDates: defaultDatesReducer,
  conditions: ConditionsReducer,
  dashboardData: dashboardReducer,
  dashboard2Data: dashboard2Reducer,
  training: TrainingReducer,
  trainingAssignment: TrainingAssignmentReducer,
  tableState: TableReducer,
  cmiAnalysis: CMIAnalysisReducer,
  orgTree: OrgTreeReducer,
  cmiComparison: CMIComparisonReducer,
  hospitalDocScoreGoalDataset: DocScoreGoalReducer,
  medicareComparison: MedicareComparisonReducer,
  breadcrumbNavigation: BreadcrumbNavigationReducer,
  menuNavigation: MenuNavigationReducer,
  cmiAnalysisSelections: CMIAnalysisSelectionsReducer,
  systemManagementData: SystemManagementReducer
});

Sentry.init({
  dsn: 'https://1ebc1015036f42a6aae080bb8b655c4f@o4504176596484096.ingest.sentry.io/4504849057775616',
  environment: SENTRY_ENVIRONMENT,
  debug: SENTRY_ENVIRONMENT !== 'production',
  // do not report to sentry for local dev
  // it does not work properly in dev env (errors get reported twice)
  // to test sentry use - npm run start:prod
  enabled: ENVIRONMENT === 'production',
  // do not waste replay storage space on general sessions, only for errors
  replaysSessionSampleRate: 0,
  // capture replays for 100% of errors that occur
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    ...Sentry.defaultIntegrations,
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false
    }),
    new Sentry.Integrations.Breadcrumbs({
      sentry: false,
      // TODO: decide if we want console logs to be displayed as breadcrumbs (poor mans breadcrumb..)
      console: false,
      // TODO: disabled for now, see notes below
      // once proper tagging in place then enable
      dom: false
      // dom: {
      //   // add the attribute to any elements you want to show as breadcrumbs (for DOM interactions)
      //   //ex: <Component sentry='label' ... />
      //   // use id as fallback for now
      //   // ideally add sentry prop to all core UI components so they are taggable
      //   serializeAttribute: ['sentry', 'id']
      // }
    }),
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    })
  ],
  normalizeDepth: 5
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware), sentryReduxEnhancer));

// Argument of type '() => Saga<any[]>' is not assignable to parameter of type 'Saga<any[]>'
// The above is fundamentally incorrect and I believe it's a type delcaration bug. The saga middleware requires a function as
// the argument, not the results of the function.
// eslint-disable-next-line
// @ts-ignore
sagaMiddleware.run(rootSaga);

const domNode = document.getElementById('root') as HTMLElement;
const root = createRoot(domNode);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
